import { type selectInputOption, type inputStates } from '../types/selectInputOption';
import {
    IssueStatus, IssueStatusDetail, IssueStatusInvites, MaterialType
} from '../../shared/shared.interface';
const nuxtApp = useNuxtApp();

export function issueStatusOptions() {
    const issueStatusOptions = Object.values(IssueStatus);
    const translateOptions = [];
    const key = 'PAGES.ISSUE_FORM.ISSUESTATUS.OPTION';
    issueStatusOptions.forEach((option) => {
        const translateOutput = translate(key, option);
        translateOptions.push(translateOutput);
    });
    return translateOptions;
}

export function issueStatusOptionsDetail() {
    return Object.values(IssueStatusDetail);
}

export function issueSeverityOptions() {
    const servityOptions = Object.values(useRuntimeConfig().public.severity);
    const translateOptions = [];
    const key = 'PAGES.ISSUE_FORM.SEVERITY.OPTION';
    servityOptions.forEach((option) => {
        const translateOutput = translate(key, option);
        translateOptions.push(translateOutput);
    });
    return translateOptions;
}
export function issueCategories() {
    const key = 'PAGES.ISSUE_FORM.CATEGORY.OPTION';
    const options = [ 'Pothole', 'Mosque' ];
    const translateOptions = [];
    options.forEach((option) => {
        const translateOutput = translate(key, option);
        translateOptions.push(translateOutput);
    });

    return translateOptions;
}

export function issueNearMe() {
    return [ '5KM',
        '10KM',
        '15KM' ];
}
export function createFieldsData() {
    const temporaryIssue = useTemporaryIssue();
    const selectFields = [{
        key: 'category',
        label: 'Select Category',
        helpingText: 'Please select the category of your issue from given options.',
        options: issueCategories(),
        selected: temporaryIssue.value.issueType
    },
    {
        key: 'severity',
        label: 'Severity Level',
        helpingText: 'Please select the severity type of your issue from given options',
        options: issueSeverityOptions(),
        selected: temporaryIssue.value.severity
    },
    {
        key: 'issueStatus',
        label: 'Issue Status',
        helpingText: 'Please select the issue status based on',
        options: issueStatusOptions(),
        selected: temporaryIssue.value.issueStatus
    }];
    const selectFieldsOptions = selectFields.map((selectField) => {
        return {
            key: selectField.key,
            label: selectField.label,
            helpingText: selectField.helpingText,
            options: selectField.options.map(option => createSelectFieldOption(option, option, selectField.selected))
        };
    });

    return selectFieldsOptions;
}

const auth0UsersData = await auth0Users();
export function editFieldsData() {
    const config = useRuntimeConfig();
    const temporaryIssue = useTemporaryIssue();
    const usersData = auth0UsersData.map((userObj:any) => {
        return {
            text: userObj.name,
            value: userObj.email
        };
    });
    const hours = Array.from({
        length: 100
    }, (_, index) => index + 1);

    const materialTypeOptions = Object.values(MaterialType);

    const quantityUsedOptions = Array.from({
        length: 30
    }, (_, index) => index * config.public.quantityUsedGap);

    const diameterOptions = Array.from({
        length: 30
    }, (_, index) => index * config.public.diameterGap);

    const depthOptions = Array.from({
        length: 30
    }, (_, index) => index * config.public.depthGap);

    const selectFields = [{
        key: 'fixTime',
        label: 'Time taken to Fix(hours)',
        helpingText: 'Please select hours',
        options: hours,
        selected: temporaryIssue.value.fixTime
    },
    {
        key: 'assignee',
        label: 'Assignee',
        helpingText: 'Please select assignee',
        options: usersData,
        selected: temporaryIssue.value.assignee
    },
    {
        key: 'materialType',
        label: 'Material Type',
        helpingText: 'Select material type',
        options: materialTypeOptions,
        selected: temporaryIssue.value.materialType
    },
    {
        key: 'quantityUsed',
        label: 'Quantity Used(Kg)',
        helpingText: 'Select quantity used in kg',
        options: quantityUsedOptions,
        selected: temporaryIssue.value.quantityUsed
    },
    {
        key: 'diameter',
        label: 'Dimension of Pothole(Diameter in inches)',
        helpingText: 'Select diameter in inches',
        options: diameterOptions,
        selected: temporaryIssue.value.diameter
    },
    {
        key: 'depth',
        label: 'Dimension of Pothole(Depth in inches)',
        helpingText: 'Select depth of pothole in inches',
        options: depthOptions,
        selected: temporaryIssue.value.depth
    }];

    const selectFieldsOptions = selectFields.map((selectField) => {
        if (selectField.key === 'assignee')
            return {
                key: selectField.key,
                label: selectField.label,
                helpingText: selectField.helpingText,
                options: selectField.options.map(option => createSelectFieldOption(option.text, option.value, selectField.selected))
            };
        else
            return {
                key: selectField.key,
                label: selectField.label,
                helpingText: selectField.helpingText,
                options: selectField.options.map(option => createSelectFieldOption(option, option, selectField.selected))
            };
    });
    return selectFieldsOptions;
}

export function createSelectFieldOption(text: string, value: any, selected: any) {
    return {
        text,
        value,
        selected: value === selected
    } as selectInputOption;
}

export function getInputFieldClasses(state: inputStates) {
    const classes: Record<inputStates, object> = {
        required: {
            input: 'border border-red-400',
            label: 'text-red-400'
        },
        disabled: {
            input: 'border border-neutral-200 bg-neutral-100 cursor-not-allowed text-neutral-400',
            label: 'text-neutral-400'
        },
        bordered: {
            input: 'border border-neutral-300',
            label: 'text-neutral-700'
        },
        default: {
            input: 'border-none',
            label: 'text-neutral-700'
        }
    };
    return classes[state];
}

function translate(key:string, field:string) {
    return nuxtApp.$i18n.t(key.toUpperCase() + '.' + field.toUpperCase());
}

export function getIssueOptions(key:string) {
    let options:any[];
    switch (key) {
    case 'category':
        options = [ 'Pothole', 'Mosque' ];
        break;
    case 'severity':
        options = Object.values(useRuntimeConfig().public.severity);
        break;
    case 'issueStatus':
        options = Object.values(IssueStatus);
        break;
    }

    return options;
}

export function getIssueStatusDetail() {
    const issueStatusInvites:IssueStatusInvites = {
    };
    const issueStatusTypes:IssueStatus[] = issueStatusOptions();
    const IssueStatusDetail:IssueStatusDetail[] = issueStatusOptionsDetail();
    issueStatusInvites._id = '';
    const issueStatusDetail = [];

    for (let index = 0; index < issueStatusTypes.length; index++) {
        const issueStatus = {
            name: issueStatusTypes[index],
            description: IssueStatusDetail[index],
            selected: false
        };
        issueStatusDetail.push(issueStatus);
    }

    issueStatusInvites.issueStatuses = issueStatusDetail;

    return issueStatusInvites;
}
